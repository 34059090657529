import AlarmCustomizationDropDowns from "./AlarmCustomizationDropDowns";
import { AlarmType, Company } from "../../../../redux/companies/companies-types";
import { AlarmCustomization } from "../../../../redux/apartments/apartments-types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/RootState";
import AlarmCustomizationHead from "./AlarmCustomizationHead";

function AlarmTypeRow({
    alarmType,
    comp,
    allCustomizations,
}: {
    alarmType: AlarmType;
    comp: Company;
    allCustomizations: AlarmCustomization[];
}) {
    let relevantCustomization = (allCustomizations || []).find((c) => c.alarmType === alarmType.alarmName) || null;

    return (
        <AlarmCustomizationDropDowns company={comp} alarmType={alarmType} alarmCustomization={relevantCustomization} />
    );
}

export type Props = {
    alarmCustomizations: AlarmCustomization[];
    company: Company;
};

const AlarmCustomizationMenu = ({ alarmCustomizations, company }: Props) => {
    const alarmTypes = useSelector((state: RootState) => state.hydrolink.companies.alarmTypes);

    return (
        <AlarmCustomizationHead>
            {alarmTypes
                .filter((alarmType) => !alarmType.adminOnly)
                .map((alarmType) => (
                    <AlarmTypeRow
                        key={alarmType.alarmName}
                        alarmType={alarmType}
                        comp={company}
                        allCustomizations={alarmCustomizations}
                    />
                ))}
        </AlarmCustomizationHead>
    );
};

export default AlarmCustomizationMenu;
