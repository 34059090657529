import { all, put, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { ApiDPAContracts } from "../../api/api-types";
import {
    FetchDPAContractsAction,
    FetchDPAContractsFailureAction,
    FetchDPAContractsSuccessAction,
    SignLinkRequestAction,
    SignLinkRequestFailureAction,
    SignLinkRequestSuccessAction,
} from "./dpa-contracts-actions";
import { convertApiDPAContracts } from "../../api/api-conversions";
import { ReportErrorAction } from "../error/error-actions";
import { SignLinkRequestPayload } from "./dpa-contracts-types";
import { AddMessageAction } from "../messages/messages-actions";
import { MessageTypes } from "../messages/messages-types";

export function* fetchDPAContracts() {
    yield api.get(
        `./dpa_contracts`,
        (resp: ApiDPAContracts) => put(FetchDPAContractsSuccessAction(convertApiDPAContracts(resp))),
        (err) => all([put(FetchDPAContractsFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* signLinkRequest(action: { payload: SignLinkRequestPayload }) {
    const contractId: number = action.payload.contractId;
    yield api.post(
        `/contracts/${contractId}/sign_link_request`,
        {},
        () =>
            all([
                put(SignLinkRequestSuccessAction({ contractId: contractId })),
                put(AddMessageAction({ type: MessageTypes.success, message: "dpa.remindSendSuccessfully" })),
            ]),
        (err) => all([put(SignLinkRequestFailureAction()), put(ReportErrorAction(err))]),
    );
}

export function* dpaContractSaga() {
    yield takeEvery(FetchDPAContractsAction, fetchDPAContracts);
    yield takeEvery(SignLinkRequestAction, signLinkRequest);
}
