import BaseState from "../../interfaces/BaseState";

export type Apartment = {
    id: number;
    code: string;
    clientId: number;
    meters: Meter[];
};

export type ApartmentOpenById = Record<number, boolean>;

export type Meter = {
    id: string;
    type: MeterType;
    warm: boolean;
    heating: boolean;
    cooling: boolean;
    humidity: boolean;
    code: string;
    secondaryAddress: string | null;
    reading: number | null;
    updated: number;
    meterResetAt: Date | null;
    earliestReading: Date | null;
    activeAlarmNames: string[];
    hasActivePremiumAlarms: boolean;
    supportsHydrodigitLRAlarmReset: boolean;
    deactivatedAlarmTypes: string[];
    devEui: string | null;
};

export type MeterType = "water" | "energy" | "ambient" | "leakage" | "smoke";

export const WATER_METER: MeterType = "water";
export const ENERGY_METER: MeterType = "energy";
export const AMBIENT_METER: MeterType = "ambient";
export const LEAKAGE_METER: MeterType = "leakage";
export const SMOKE_METER: MeterType = "smoke";

export type MeterHistoryType = "water" | "energy" | "ambient";

export type FetchApartmentsPayload = {
    companyId: number;
};

export type FetchApartmentsSuccessPayload = Apartment[];

export type MeterHistoryReading = {
    created: Date;
    value: number | null;
};

export interface ApartmentsState extends BaseState {
    apartments: Apartment[];
    usageByMeterId: Record<number, MeterHistoryReading[]>;
    chartState: ChartState;
    isReportingOpen: boolean;
    premiumSettings: PremiumSettings;
    alarmSettings: AlarmSettings;
    reportSettings: ReportSettings;
    reportFormats: ReportFormat[];
    apartmentOpenById: ApartmentOpenById;
    allApartmentsOpen: boolean;
}

export type ChartType = "day" | "week" | "month" | "year";

export type ChartState = {
    apartmentId: number | null;
    isChartOpen: boolean;
    type: ChartType;
    offsetDays: number;
    meterType: MeterHistoryType | null;
};

export type PremiumSettings = {
    phoneNumbers: string;
    warmWaterPrice: string;
    coldWaterPrice: string;
    energyPrice: string;
    billingStart: string | null;
    billingPeriodMonths: number | null;
};

export enum Interval {
    Daily,
    Weekly,
    Monthly,
    New,
    Never,
}

export type AlarmCustomization = {
    companyId: number | null | undefined;
    alarmType: string;
    highPriority: boolean | null;
    notifyOnNew: boolean | null;
    alarmInterval: string | null;
};

export type FetchPremiumSettingsPayload = {
    companyId: number;
};

export type SavePremiumSettingsPayload = {
    settings: PremiumSettings;
    companyId: number;
};

export type AlarmSettings = {
    stuckEmail: string;
    alarmLang: string;
    alarmCustomizations: AlarmCustomization[];
};

export type FetchAlarmSettingsPayload = {
    companyId: number;
};

export type SaveAlarmSettingsPayload = {
    alarmSettings: AlarmSettings;
    companyId: number;
};

export type SaveAlarmCustomizationPayload = {
    alarmCustomization: AlarmCustomization;
    companyId: number;
};

export type ReportSettings = {
    reportingId: string;
    sendReports: boolean;
    reportEmail: string;
    reportFormat: string;
    reportIntervalMonths: number;
    reportLang: string;
    reportLastSent?: string;
    reportStart?: string;
};

export type FetchReportSettingsPayload = {
    companyId: number;
};

export type SaveReportSettingsPayload = {
    reportSettings: ReportSettings;
    companyId: number;
};

export type SendTestReportPayload = {
    companyId: number;
};

export type UploadCSVPayload = {
    companyId: number;
    file: string;
};

export type DeleteMeterPayload = {
    companyId: number;
    id: number;
    type: MeterType;
};

export type FetchGraphsPayload = {
    apartmentId: number;
    type: ChartType;
    offset: number;
    meterType: MeterHistoryType | null;
};

export type DownloadMeterUsageReportPayload = {
    apartmentId: number;
    type: ChartType;
    offset: number;
    meterType: MeterHistoryType;
};

export type FetchGraphsSuccessPayloadWaterMeterData = {
    created: Date;
    usageLitres: number | null;
};

export type FetchGraphsSuccessPayloadEnergyMeterData = {
    created: Date;
    usageKwh: number;
};

export type FetchGraphsSuccessPayloadTemperatureMeterData = {
    timestamp: Date;
    averageTemperatureCelsius: number;
    averageHumidityPercent?: number;
};

export type FetchGraphsSuccessPayload = {
    usageByMeterId?: Record<number, FetchGraphsSuccessPayloadWaterMeterData[]>;
    usageByEnergyMeterId?: Record<number, FetchGraphsSuccessPayloadEnergyMeterData[]>;
    byTemperatureMeterId?: Record<number, FetchGraphsSuccessPayloadTemperatureMeterData[]>;
};

export type DeleteApartmentPayload = {
    companyId: number;
    id: number;
};

export type ReportFormatsPayload = ReportFormat[];

export enum ReportResoluton {
    DAY,
    MONTH,
}

export type ReportFormat = {
    id: string;
    name: string;
    resolution: ReportResoluton;
};

export type ChangeMeterPayload = {
    apartmentId: number;
    companyId: number;
    meterId: number;
    date: Date;
    valueBefore?: number;
    valueAfter?: number;
};

export type UpdateApartmentNamePayload = {
    apartmentId: number;
    companyId: number;
    name: string;
};

export type HydrodigitLRAlarmResetPayload = {
    meterId: number;
};

export type ToggleApartmentPayLoad = {
    id: number;
    isOpen: boolean;
};

export type ToggleAlarmByMeterIdPayLoad = {
    meterId: number;
    companyId: number;
    meterType: string;
    alarmType: string;
    deactivate: boolean;
};

export type SendSetDataLoggerDownlinkPayload = {
    meterId: number;
    meterType: string;
    value: boolean;
};
