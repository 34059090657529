import { createAction } from "@reduxjs/toolkit";
import {
    FetchDPAContractsSuccessPayload,
    SetSignLinkRequestSentByContractIdPayload,
    SignLinkRequestPayload,
    SignLinkRequestSuccessPayload,
} from "./dpa-contracts-types";

export const FetchDPAContractsAction = createAction<void>("FETCH_DPA_CONTRACTS");
export const FetchDPAContractsSuccessAction =
    createAction<FetchDPAContractsSuccessPayload>("FETCH_DPA_CONTRACTS_SUCCESS");
export const FetchDPAContractsFailureAction = createAction<void>("FETCH_DPA_CONTRACTS_FAILURE");

export const SignLinkRequestAction = createAction<SignLinkRequestPayload>("SIGN_LINK_REQUEST");
export const SignLinkRequestSuccessAction = createAction<SignLinkRequestSuccessPayload>("SIGN_LINK_REQUEST_SUCCESS");
export const SignLinkRequestFailureAction = createAction<void>("SIGN_LINK_REQUEST_FAILURE");
export const SetSignLinkRequestSentByContractIdAction = createAction<SetSignLinkRequestSentByContractIdPayload>(
    "SET_SIGN_LINK_REQUEST_SENT_BY_CONTRACT_ID",
);
