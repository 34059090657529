import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { TableCell, TableRow } from "../../../../components/Table";
import { useTranslation } from "react-i18next";
import { CustomTooltip } from "../../../../components/CustomTooltip";

export type Props = {
    children: React.ReactNode;
    simplifiedHead?: boolean;
};

const AlarmCustomizationMenu = ({ simplifiedHead, children }: Props) => {
    const t = useTranslation().t;

    return (
        <div style={{ marginTop: "1em" }}>
            <span style={{ marginTop: "1em" }} className="text-bold">
                {t("alarms.alarmSettings")}
            </span>
            <TableContainer component="div">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("apartments.alarm")}</TableCell>
                            {!simplifiedHead && (
                                <TableCell>
                                    <CustomTooltip title={t("alarms.highPriorityTooltip") || ""}>
                                        <span className="contains-tooltip">{t("alarms.highPriority")}</span>
                                    </CustomTooltip>
                                </TableCell>
                            )}
                            <TableCell>
                                <CustomTooltip title={t("alarms.notifyNewTooltip") || ""}>
                                    <span className="contains-tooltip">{t("alarms.notifyNew")}</span>
                                </CustomTooltip>
                            </TableCell>
                            {!simplifiedHead && (
                                <>
                                    <TableCell>
                                        <CustomTooltip title={t("alarms.intervalTooltip") || ""}>
                                            <span className="contains-tooltip">{t("alarms.intervalColumn")}</span>
                                        </CustomTooltip>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>{children}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AlarmCustomizationMenu;
