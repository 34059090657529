import React from "react";
import { Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { StyledMenuTypography } from "./HamburgerMenu.style";
import { Link } from "react-router-dom";
import { colors, typography } from "../../../theme";
import { useHasPrivileges } from "../../../utils/useHasPrivilegesOf";

/* TODO: DPA tab is commented out as the feature is not yet complete.
It can be returned when it is in use. */
enum Tab {
    companies = "companies",
    users = "users",
    guides = "guides",
    // dpa = "dpa-contracts",
    termsAndConditions = "view-terms-and-conditions",
}

function isHiddenItem(tabType: Tab, _canSeeDPA: boolean): boolean {
    switch (tabType) {
        case Tab.users:
            return true;
        // case Tab.dpa:
        //     return !canSeeDPA;
    }
    return false;
}

export default function HamburgerMenu() {
    const { t } = useTranslation();
    const canSeeAllMenuTabs = useHasPrivileges("can see admin hamburger menu tabs").hasPrivilege;
    const canSeeDPA = useHasPrivileges("can access dpa contracts management").hasPrivilege;
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const onMenuOpen = (currentTarget: Element) => {
        setAnchorEl(currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const tabs: Tab[] = [Tab.companies, Tab.users, Tab.guides, Tab.termsAndConditions];

    const hamburgerMenuStyle = {
        color: colors.blue00,
        fontSize: typography.fontSize.hambugerMenu,
    };
    return (
        <div>
            <Grid item>
                <IconButton edge="start" onClick={(e) => onMenuOpen(e.currentTarget)}>
                    <MenuIcon style={hamburgerMenuStyle}></MenuIcon>
                </IconButton>
                <Menu open={Boolean(anchorEl)} onClose={onMenuClose} anchorEl={anchorEl} disableScrollLock>
                    {tabs
                        .filter((tabType) => canSeeAllMenuTabs || !isHiddenItem(tabType, canSeeDPA))
                        .map((tabType, i) => (
                            <Link to={`/${tabType}`} key={i}>
                                <MenuItem
                                    onClick={() => {
                                        onMenuClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <img alt="icon" src={require(`../../../assets/ICON_menu_${tabType}.svg`)} />
                                    </ListItemIcon>
                                    <StyledMenuTypography>{t(`landing.${tabType}`)}</StyledMenuTypography>
                                </MenuItem>
                            </Link>
                        ))}
                </Menu>
            </Grid>
        </div>
    );
}
