export const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <p style={{ textDecoration: "underline" }}>onlinetuki@hydrolink.fi</p>
                <p>|</p>
                <p>Koka Oy 2024</p>
                <p>|</p>
                <p>010 286 2020</p>
            </div>
        </div>
    );
};
